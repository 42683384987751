import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <iframe
        src="https://drive.google.com/file/d/1y0MhCDE0--obnwLjvWXoV9jO8VXr5xEm/preview"
        id="portfolio"
      ></iframe>
    </div>
  );
}

export default App;
